var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.showPage,
          expression: "showPage",
        },
      ],
    },
    [
      !_vm.showPage
        ? _c(
            "div",
            {
              staticClass: "formMain",
              staticStyle: { width: "100%", background: "#ffffff" },
            },
            [
              _c("head-layout", {
                attrs: {
                  showIcon: false,
                  "head-title": "项目概况",
                  "head-btn-options": _vm.basicFormHeadBtnOptions,
                },
                on: {
                  "head-save": function ($event) {
                    return _vm.headSave(false)
                  },
                  "head-saveBack": _vm.closeDialog,
                },
              }),
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  attrs: {
                    model: _vm.dataForm,
                    "label-width": "160px",
                    disabled: _vm.formType == "view",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "项目编码", prop: "prjCode" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: "",
                                  placeholder: "请输入项目编码",
                                },
                                model: {
                                  value: _vm.dataForm.prjCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "prjCode", $$v)
                                  },
                                  expression: "dataForm.prjCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "项目名称", prop: "prjName" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: "",
                                  placeholder: "请输入项目名称",
                                },
                                model: {
                                  value: _vm.dataForm.prjName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "prjName", $$v)
                                  },
                                  expression: "dataForm.prjName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-form-item", { attrs: { label: "" } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "150px",
                                  height: "150px",
                                  position: "absolute",
                                  "text-align": "center",
                                  "z-index": "1",
                                },
                              },
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px",
                                  },
                                  attrs: { src: _vm.qrcodeSrc },
                                }),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.downloadQrcode.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("下载\n              ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "项目简称", prop: "prjAs" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请填写项目简称",
                                  maxlength: "10",
                                },
                                model: {
                                  value: _vm.dataForm.prjAs,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "prjAs", $$v)
                                  },
                                  expression: "dataForm.prjAs",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "项目区域", prop: "areName" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: "",
                                  placeholder: "请填写项目区域",
                                },
                                model: {
                                  value: _vm.dataForm.areName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "areName", $$v)
                                  },
                                  expression: "dataForm.areName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "承接单位",
                                prop: "contractorName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.dataForm.contractorName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "contractorName",
                                      $$v
                                    )
                                  },
                                  expression: "dataForm.contractorName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "执行单位", prop: "contractId" },
                            },
                            [
                              _vm.formType != "view"
                                ? _c("avue-input-tree", {
                                    attrs: {
                                      props: {
                                        label: "title",
                                        value: "id",
                                        leaf: "leaf",
                                      },
                                      checkStrictly: "",
                                      "default-expand-all": "",
                                      placeholder: "请选择执行单位",
                                      dic: _vm.organDic,
                                      nodeClick: _vm.nodeClick,
                                    },
                                    model: {
                                      value: _vm.dataForm.contractId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "contractId",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.contractId",
                                    },
                                  })
                                : _c("el-input", {
                                    model: {
                                      value: _vm.dataForm.contractName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "contractName",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.contractName",
                                    },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "项目经理", prop: "pmCame" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入项目经理" },
                                model: {
                                  value: _vm.dataForm.pmCame,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "pmCame", $$v)
                                  },
                                  expression: "dataForm.pmCame",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "联系电话", prop: "phoneNumber" },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入联系电话" },
                                model: {
                                  value: _vm.dataForm.phoneNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "phoneNumber", $$v)
                                  },
                                  expression: "dataForm.phoneNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "入网日期", prop: "prjJoinDate" },
                            },
                            [
                              _c("avue-date", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  format: "yyyy-MM-dd",
                                  valueFormat: "yyyy-MM-dd",
                                  "picker-options": _vm.pickerOptions,
                                  placeholder: "请选择入网日期",
                                },
                                model: {
                                  value: _vm.dataForm.prjJoinDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "prjJoinDate", $$v)
                                  },
                                  expression: "dataForm.prjJoinDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "卫星电话数量",
                                prop: "phoneCount",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请填写卫星电话数量",
                                  oninput:
                                    "value = value.replace(/[^\\d]/g,'').slice(0, 3)",
                                },
                                model: {
                                  value: _vm.dataForm.phoneCount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "phoneCount", $$v)
                                  },
                                  expression: "dataForm.phoneCount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "项目坐标", prop: "coordinate" },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请填写项目坐标" },
                                on: { focus: _vm.openMap },
                                model: {
                                  value: _vm.dataForm.coordinate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "coordinate", $$v)
                                  },
                                  expression: "dataForm.coordinate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "项目位置", prop: "areaId" } },
                            [
                              _c("el-cascader", {
                                ref: "prjAreaCascader",
                                staticStyle: { width: "100%" },
                                attrs: { filterable: "", options: _vm.areaDic },
                                on: { change: _vm.handleChangeArea },
                                model: {
                                  value: _vm.dataForm.areaId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "areaId", $$v)
                                  },
                                  expression: "dataForm.areaId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "详细位置", prop: "areaRestock" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请填写",
                                  maxlength: "50",
                                },
                                model: {
                                  value: _vm.dataForm.areaRestock,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "areaRestock", $$v)
                                  },
                                  expression: "dataForm.areaRestock",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "所属板块", prop: "prjType" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.dataForm.prjType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "prjType", $$v)
                                    },
                                    expression: "dataForm.prjType",
                                  },
                                },
                                _vm._l(_vm.prjTypeList, function (item) {
                                  return _c("el-option", {
                                    key: item.dictKey,
                                    attrs: {
                                      label: item.dictValue,
                                      value: item.dictKey,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "项目状态", prop: "stage" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.dataForm.stage,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "stage", $$v)
                                    },
                                    expression: "dataForm.stage",
                                  },
                                },
                                _vm._l(_vm.stageList, function (item) {
                                  return _c("el-option", {
                                    key: item.dictKey,
                                    attrs: {
                                      label: item.dictValue,
                                      value: item.dictKey,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "开始日期",
                                prop: "startWorking",
                              },
                            },
                            [
                              _c("avue-date", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  "picker-options": _vm.planStartDateOptions,
                                  format: "yyyy-MM-dd",
                                  valueFormat: "yyyy-MM-dd",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                },
                                model: {
                                  value: _vm.dataForm.startWorking,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "startWorking", $$v)
                                  },
                                  expression: "dataForm.startWorking",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "结束日期", prop: "planEndDate" },
                            },
                            [
                              _c("avue-date", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  valueFormat: "yyyy-MM-dd",
                                  "picker-options": _vm.planEndDateOptions,
                                  placeholder: "请选择入网日期",
                                },
                                model: {
                                  value: _vm.dataForm.planEndDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "planEndDate", $$v)
                                  },
                                  expression: "dataForm.planEndDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "纳入安全管理",
                                prop: "prjInfoState",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.dataForm.prjInfoState,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataForm,
                                        "prjInfoState",
                                        $$v
                                      )
                                    },
                                    expression: "dataForm.prjInfoState",
                                  },
                                },
                                _vm._l(_vm.yes_no, function (item) {
                                  return _c("el-option", {
                                    key: item.dictKey,
                                    attrs: {
                                      label: item.dictValue,
                                      value: item.dictKey,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "境内外", prop: "isDomestic" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择境内外",
                                    disabled: "",
                                  },
                                  model: {
                                    value: _vm.dataForm.isDomestic,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "isDomestic", $$v)
                                    },
                                    expression: "dataForm.isDomestic",
                                  },
                                },
                                _vm._l(_vm.prjDomesticList, function (item) {
                                  return _c("el-option", {
                                    key: item.dictKey,
                                    attrs: {
                                      label: item.dictValue,
                                      value: item.dictKey,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "项目进度", prop: "progress" } },
                            [
                              _c("el-input-number", {
                                attrs: { size: "medium", min: 0, max: 100 },
                                model: {
                                  value: _vm.dataForm.progress,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "progress", $$v)
                                  },
                                  expression: "dataForm.progress",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "项目类型",
                                prop: "businessTypeCode",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择项目类型" },
                                  model: {
                                    value: _vm.dataForm.businessTypeCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataForm,
                                        "businessTypeCode",
                                        $$v
                                      )
                                    },
                                    expression: "dataForm.businessTypeCode",
                                  },
                                },
                                _vm._l(_vm.businessTypeMap, function (item) {
                                  return _c("el-option", {
                                    key: item.dictKey,
                                    attrs: {
                                      label: item.dictValue,
                                      value: item.dictKey,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("qrcodeDialog", { ref: "qrcodeDialog" }),
      _c("BaiduMap", {
        ref: "BaiduMap",
        on: { change: _vm.handleMapSave },
        model: {
          value: _vm.dataForm,
          callback: function ($$v) {
            _vm.dataForm = $$v
          },
          expression: "dataForm",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }