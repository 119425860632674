<template>
    <div>
        <el-main>
            <head-layout
                :head-btn-options="headBtnOptions"
                :head-title="this.$route.query.type == 'edit' ? $t('cip.plat.authority.role.title.editHeadTitle') : $t('cip.plat.authority.role.title.addHeadTitle')"
                @head-save="headSave('save')"
                @head-save-back="headSave('back')"
                @head-cancel="headCancel">
            </head-layout>
            <!-- <form-layout ref="formLayout" icon="" :head-title="headtitle" page-type="self" :option="option"
      :btn-options="btnOptions" @form-btn-save-click="save" :dataForm="form"></form-layout>-->
            <form-layout :column="column" :dataForm.sync="dataObj" ref="formLayout"></form-layout>
        </el-main>
    </div>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import website from '@/config/website';
import { mapGetters } from "vuex";
import { add, update, getRoleTreeById, getDetail} from "@/api/system/role";
export default {
    components: { FormLayout, HeadLayout },
    data() {
      let validatePass = (rule, value, callback) => {
        if (value != '') {
          let val = JSON.parse(value)
          let language = this.language
          let val2 = val[language]
          if (val2.substring(0,1)==" " || val2.substring(val2.length-1)==" ") {
            callback(new Error(this.$t('cip.cmn.rule.deleteWarning')))
          }
          if (val2.length > 100) {
            callback(new Error(this.$t('cip.cmn.rule.nameLength10Warning')));
          } else {
            callback();
          }
        } else {
          callback(new Error(this.$t("cip.plat.authority.role.field.roleName") + this.$t('cip.cmn.rule.noEmptyWarning')));
        }
      };
        return {
            headBtnOptions: [{
                label: this.$t('cip.cmn.btn.saveBtn'),
                emit: "head-save",
                type: "button",
                icon: ""
            },
            {
                label: this.$t('cip.cmn.btn.saveBackBtn'),
                emit: "head-save-back",
                type: "button",
                icon: ""
            }, {
                label: this.$t('cip.cmn.btn.celBtn'),
                emit: "head-cancel",
                type: "button",
                icon: ""
            }],
            dataObj: {},
            parentId: 0,
            column: [
                {
                    label: this.$t("cip.plat.authority.role.field.roleName"),
                    prop: "roleName",
                    search: true,
                    isGlobal:true, //是否支持国际化
                    rules: [{ required: true, validator: validatePass, trigger: 'blur' }]
                },
                {
                    label: this.$t("cip.plat.authority.role.field.tenantId"),
                    prop: "tenantId",
                    type: "select",
                    dicUrl: "/api/sinoma-system/tenant/select",
                    addDisplay: false,
                    editDisplay: false,
                    viewDisplay: website.tenantMode,

                    props: {
                        label: "tenantName",
                        value: "tenantId"
                    },
                    hide: !website.tenantMode,
                    search: website.tenantMode,
                    rules: [{
                        required: true,
                        message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.authority.role.field.tenantId"),
                        trigger: "click"
                    }]
                },
                {
                    label: this.$t("cip.plat.authority.role.field.roleAlias"),
                    prop: "roleAlias",
                    search: true,
                    maxlength: 60,
                    showWordLimit: true,
                    rules: [{
                        required: true,
                        message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.authority.role.field.roleAlias"),
                        trigger: 'blur'
                    },{
                      pattern: /^[^\s]+(\s+[^\s]+)*$/,
                      message: this.$t("cip.cps.msg.removeSpaces"),
                    },
                      {
                        pattern: '^[a-zA-Z0-9_]{0,}$',
                        message: this.$t("cip.cps.device.msg.chinese"),
                      }
                    ]
                },
                {
                    label: this.$t("cip.plat.authority.role.field.roleType"),
                    type: "select",
                    dicUrl: "/api/sinoma-system/dict/dictionary?code=user_type",
                    props: {
                      label: "dictValue",
                      value: "dictKey"
                    },
                    span: 8,
                    value: 1,
                    disabled:true,
                    display:false,
                    dataType: "number",
                    prop: "roleType",
                    rules: [{
                    required: true,
                    message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.authority.role.field.roleType"),
                    trigger: "blur"
                  }]
                },
                // {
                //     label: this.$t("cip.plat.authority.role.field.parentId"),
                //     prop: "parentId",
                //     dicData: [],
                //     type: "tree",
                //     hide: true,
                //
                //     props: {
                //         label: "title"
                //     },
                //     rules: [
                //         {
                //             required: false,
                //             message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.authority.role.field.parentId"),
                //             trigger: "click"
                //         }
                //     ]
                // },
                {
                    label: this.$t("cip.plat.authority.role.field.sort"),
                    prop: "sort",
                    type: "number",
                    value: 0,
                    minRows: 0,
                    maxRows: 1000000000,
                },
              // {
              //   label: "分级管理",
              //   prop: "isClassificationRole",
              //   type: 'radio',
              //   value: 1,
              //   props: {
              //     label: "dictValue",
              //     value: "dictKey"
              //   },
              //   dataType: "number",
              //   dicUrl: "/api/sinoma-system/dict/dictionary?code=yes_no",
              // },
              // {
              //   label: "项目管理",
              //   prop: "isProjectRole",
              //   type: 'radio',
              //   value: 1,
              //   props: {
              //     label: "dictValue",
              //     value: "dictKey"
              //   },
              //   dataType: "number",
              //   dicUrl: "/api/sinoma-system/dict/dictionary?code=yes_no",
              // }
            ]
        }
    },
    computed: {
        ...mapGetters(['language'])
    },
    created() {

      if(this.$route.query.tenantStatus != "true"){
        this.findObject(this.column, "tenantId").display = false
        this.dataObj.tenantId = "000000";
      }
        // await getRoleTreeById().then(res => {
        //   const column = this.findObject(this.column, "parentId");
        //   column.dicData = res.data.data;
        // });
        // if(this.$router)
      if (this.$route.query.type == 'edit') {
          getDetail(this.$route.query.id).then(res => {
            const {data} = res;
            this.dataObj = data.data;
          })
      }

    },
    mounted() { },
    methods: {
        headSave(type) {
            this.$refs.formLayout.$refs.form.validate(valid => {
                if (valid) {
                  this.$loading()
                    let obj = this.$refs.formLayout.dataForm
                    let requestType = obj.hasOwnProperty('id') ? update : add
                    requestType(this.dataObj).then((res) => {
                        if (type == 'back') {
                          this.$router.$avueRouter.closeTag();
                          this.$router.back();
                        }
                        let { data } = res.data
                        this.$message({
                            type: "success",
                            message: this.$t("cip.cmn.msg.success.operateSuccess"),
                        });
                        this.dataObj = data;
                        //
                    }, error => {
                        window.console.log(error);

                        this.$refs.formLayout.$refs.form.allDisabled = false
                    }).finally(()=>{
                      this.$loading().close()
                    });
                } else {
                    this.$refs.formLayout.$refs.form.allDisabled = false
                }
            })
        },
        headCancel() {
          this.$router.$avueRouter.closeTag();
          this.$router.back();
        },
    }
}
</script>

<style>
</style>
